import React from "react";
import { CreditCardIcon, CurrencyDollarIcon, EmojiHappyIcon, LightBulbIcon } from "@heroicons/react/outline";

const Feature = ({ Icon, title, body }) => (
  <div className="flex flex-col items-center md:items-start max-w-md sm:mx-auto sm:flex-row">
    <div className="mr-4">
      <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-blue-50">
        <Icon className="w-8 h-8 text-light-blue-400" />
      </div>
    </div>
    <div className="flex flex-col items-center md:items-start">
      <h6 className="mb-3 text-xl font-bold leading-5">{title}</h6>
      <p className="mb-3 text-sm text-gray-900">
        {body}
      </p>
    </div>
  </div>
);

export const Features = ({ name }) => (
  <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8">
    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-6xl md:mb-12">
      <div className="text-center">
        <p
          className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
          INCREDIBLE PRICES
        </p>
      </div>
      <h2
        className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl text-center md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="27df4f81-c854-45de-942a-fe90f7a300f9"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#27df4f81-c854-45de-942a-fe90f7a300f9)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative">Our</span>
          </span>{" "}
        Expert Voice For Insurance Coverage
      </h2>
      <div className="text-left">
        <p className="text-base text-gray-700 md:text-lg">
          RateInsider.com takes all the hard work out of shopping for {name.toLowerCase()} insurance.
          There are no obligations and you are free to accept or deny any offers our partner agents present to you based
          off the information you have
          provided. Just type in some basic information about yourself and your vehicle, sit back and relax, and prepare
          for some huge savings. RateInsider.com will do the stressful part, and let you reap the rewards!
        </p>
      </div>
    </div>
    <div className="grid max-w-screen-lg gap-8 row-gap-10 mx-auto lg:grid-cols-2">
      <Feature
        Icon={LightBulbIcon}
        title="Simplified Process"
        body="Filling out confusing forms,
            trying to keep track of rates and coverage from different insurance companies can be impossible. This is
            where RateInsider.com comes in to simplify the entire process."
      />
      <Feature
        Icon={CurrencyDollarIcon}
        title="Saves Money"
        body="Get access to helpful information and a suite of services to simplify your life from our Rateinsider partners. Plus, take advantage of great discounts, promotions and free offers."
      />
      <Feature
        Icon={CreditCardIcon}
        title="Customized Rates"
        body="RateInsider.com helps by gathering information from you through a short series of simply worded questions, then connects you to insurance agents who will provide quotes for optimal coverage."
      />
      <Feature
        Icon={EmojiHappyIcon}
        title="Customer Satisfaction"
        body="Our partners have top most customer satisfaction rate by having years of experience and great loyal customers in the insurance industry."
      />
    </div>
  </div>
);